import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Select from 'react-select';
// Redux
import { connect,useSelector } from "react-redux";
import { Link } from "react-router-dom";
// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import { getCompaniesStorage,getStorageCompany } from '../../../helpers/api_helper';

const ProfileMenu = props => {
  const companies = getCompaniesStorage();
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const { user } = JSON.parse(localStorage.getItem("authUser"));
  const [username, setusername] = useState("Admin");
  const company = getStorageCompany();

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  const changeCompany = (e) => {
    let storage = JSON.parse(localStorage.getItem("authUser"));
    storage.company=e
    localStorage.setItem('authUser',JSON.stringify(storage));
    location. reload()
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <i className="bx bx-user font-size-16 align-middle me-1" />
            {user.user?.name}
          
          <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" href={`/users/profile/${user.user?.id}`}>
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {user.user?.name}
          </DropdownItem>
          {user.profile_id==1 &&
          <>
          <label className="form-label">Empresa:</label>
          <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                defaultValue={company}
                isSearchable={true}
                getOptionLabel={(option) => `${option.name}`}
                getOptionValue={(option) => `${option.id}`}
                name="color"
                options={companies}
                onChange={ e => changeCompany(e) }
              />
              </>
              }
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>Cerrar sesión</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default 
  connect(mapStatetoProps, {})((ProfileMenu)
);