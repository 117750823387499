//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"
export const POST_LOGIN_TOKEN = "/login"
export const RESET_PASSW = "/resetPasword"
export const CHANGE_PASSW = "/changePasword"
export const SEND_CONFIRMATION = "/verify"
//LOGIN
//export const POST_FAKE_LOGIN = "/post-fake-login"
//export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
//export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
//export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"

//PROFILE
//export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
//export const POST_EDIT_PROFILE = "/post-fake-profile"
//catalogs
//Users Resource (Store, Update, Show, Index)
export const RESOURCE_USERS = "/users"
export const FILE_PROFILE_USER = "/user/file"
export const GET_PROFILES = "/user/profiles"
export const RESOURCE_PRODUCTS = "/products"
export const RESOURCE_CATEGORIES = "/categories"

//Configuration Resource
export const RESOURCE_CONFIGURATIONS = "/configurations"
export const SELECTS_CONFIGURATIONS= "/configuration/selects"
export const GET_ACTIVE_CONFIGURATION = "/configuration/showActive"

//Company Resource
export const RESOURCE_COMPANIES = "/companies"

//PROFILES -PERMISSIONS
export const RESOURCE_PROFILES = "/profiles"
export const RESOURCE_PERMISSIONS = "/permissions"
export const PERMISSIONS_SELECT = "/permission/selects"




