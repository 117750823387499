import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postProfile,putProfile,getCompanies } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import Validate from './Validate'

const AddUpdModal = ({modal,setModal,items, setItems, setProfile,profile}) => {
	const [selects, setSelects] = useState({profiles:[{id:0,name:''}],companies:[{id:0,name:''}]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
		setProfile( {...profile,
    		id: 0,
    		name: '',
    	});	
    }

    const handlerOnChange = (value,name) => {
		setProfile({...profile, [name]:value});
    }

    const save = () => {
    	ToastHelper('mensaje','error')
    	setErrors([])
    	const validate = Validate(profile);
    	if(validate != undefined){
            setErrors(validate);
            setLoading(false)
            return;
        }
    	setLoading(true)
    	if(modal.update){
    		putProfile(profile).then(response => {
    			responseAction(response);			
    		})
    	}else{
    		postProfile(profile).then(response => {
    			responseAction(response);
    		})	
    	}
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.profile;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
    	getProfilescat().then(response => {
    		console.log()
    		if(authUser.profile_id==1){
    			getCompanies().then(resp => {
					//setSelects({...selects,});
					setSelects({...selects,companies:resp.companies,profiles:response.profiles});
				});	
    		}else{
    			setSelects({...selects,profiles:response.profiles});
    		}
    		
    	});
    	
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>{modal.update ? 'Editar ':'Agregar '}Empresa</ModalHeader>
				<ModalBody>
				<div className="row">
					<div className="col-sm-12">
					    <label className="form-label">Nombre:</label>
					    <input className="form-control" name="name" type="text" onChange={ e => handlerOnChange(e.target.value,'name') } value={profile.name}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.name && errors.name[0]}</div>
					    }
					</div>
				</div>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save} disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;