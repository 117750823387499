import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Label, Card, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postUser,putUser,getCompanies, getCategoriesParents, postCategory, putCategory } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import Validate from './Validate'

const AddUpdModal = ({modal,setModal,items, setItems, setCategory, category}) => {
	const [selects, setSelects] = useState({
		categories:[{id:0,name:''}],
		parentCategories: [],
	});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);

	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
		setCategory( {...category,
    		id: 0,
    		name: '',
    		parent_id: null,
    		parentCategory: {
    			id: 0,
    			name: ''
    		},
    	});	
    }

    const handlerOnChange = (value,name) => {
		if(name=='parent_id'){
    		setCategory({...category, ['parent_id'] : value.id});
    	} else {
			setCategory({...category, [name]:value});
		}
    }

    const save = () => {
    	ToastHelper('mensaje','error')
    	setErrors([])
    	const validate = Validate(category);
    	if(validate != undefined){
            setErrors(validate);
			console.log(validate)
            setLoading(false)
            return;
        }
    	setLoading(true)
    	if(modal.update){
    		putCategory(category).then(response => {
    			responseAction(response);			
    		})
    	}else{
    		postCategory(category).then(response => {
    			responseAction(response);
    		})	
    	}
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.category;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
		const a = async () => {
			let resp = await getCategoriesParents();
			setSelects({...selects, parentCategories: resp.parentCategories});
		}
    	a();
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>Agregar / Editar</ModalHeader>
				<ModalBody>
				<div className="row">

					<div className="col-sm-12">
					    <label className="form-label">Nombre:</label>
					    <input className="form-control" name="name" type="text" onChange={ e => handlerOnChange(e.target.value,'name') } value={category.name}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.name && errors.name[0]}</div>
					    }
					</div>

					<div className="col-sm-6">
					    <label className="form-label">Categoría Padre:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							isClearable={true}
							defaultValue={selects.parentCategories.find(cat => cat.id === category.parent_id)}
							isSearchable={true}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option.id}`}
							name="color"
							options={selects.parentCategories.filter(e => { return e.id != category.id} )}
							onChange={ e => handlerOnChange(e, 'parent_id') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.parent_id && errors.parent_id[0]}</div>
					    }
					</div>
				</div>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save} disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;