import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTableHelper from "../../helpers/tables/DataTableHelper"
import Columns from './Columns'
import AddUpdModal from './AddUpdModal'
import { getUsers,deleteUser, getProducts, getCategoriesParents, postFileProfile, postFileProduct } from '../../helpers/backend_helper';
import {DestroyAlertHelper,Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import { getPolicyUser } from '../../helpers/api_helper';
import {Button, Container, Modal, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import formato from '../../assets/formato_productos_labiomola.xlsx';
import ValidateFileHelper from "../../helpers/ValidateFileHelper";


const Products = () => {
    const policy = getPolicyUser();
    
    const [file, setFile] = useState(null);
    const [loadingImport, setLoadingImport] = useState(false);
    const [pending, setPending] = useState(false);
    const [modal, setModal] = useState({show:false,update:false});
    const [modalImport, setModalImport] = useState(false);
    const [errorImport, setErrorImport] = useState([]);
    const [dataPag, setDataPag] = useState({});
    const [categories, setCategories] = useState([]);
    const [filters,setFilters] = useState({page:1,perPage:20,search:'',orderBy:'id',order:'asc'});
    const [items, setItems] = useState([]);
    const [user, setUser] = useState({
        id: 0,
        name: '',
        code: '',
        test: '',
        desc: '',
        type: '',
        subtype: '',
        eta: '',
        category_id: null,
    });
	const dispatch = useDispatch()
    useEffect(() => {
        console.log(filters)
        //ToastHelper('mensaje','error')
        let params={params:filters}
        setPending(true)
        
        getProducts(params).then(response => {
            setItems(response.data)
            setDataPag(response.meta)
        })
    
        setPending(false)
    }, [filters])

    useEffect(() => {
		const a = async () => {
			let resp = await getCategoriesParents();
			setCategories(resp.parentCategories);
		}
    	a();
    }, [])


    const addUpd = (row) => {
        if(row.id){
            setUser(row)
            setModal({...modal,show:true,update:true})
        }else{
            setModal({...modal,show:true,update:false})
        }
    }
    const destroy = (id) => {
        DestroyAlertHelper(async confirmed => {
            if (!confirmed) { return; }
            deleteUser(id).then(response => {
                Alert(response.message,response.status)
                if (response.status) {
                    setItems(
                        items.filter(i => i.id != id)
                    );
                }           
            })
        });
    }

    const fileValidate = (file) => {
        setErrorImport({})
        const validate = ValidateFileHelper(file, {required: true, type: 'xls', size: 1}, 'Excel');
        console.log('validate', validate)
        if(validate != undefined){
            setErrorImport(validate);
            return;
        }
        setFile(file);
    }

    const doImport = () => {
        let auth = JSON.parse(localStorage.getItem("authUser"))
        setLoadingImport(true)

        postFileProduct(file).then(response => {
            Alert(response.message,response.status)
            if (response.status) {
                setFilters(filters)
                setLoadingImport(false)
            } else {
                setLoadingImport(false)
            }
        }).catch(e => {
            setLoadingImport(false)
        })
    }

    const columns = Columns(addUpd,destroy);
	document.title = "Products";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="col-12">
                        <DataTableHelper tittle="Productos" columns={columns} items={items} pending={pending} config={dataPag} filters={filters} setFilters={setFilters}>
                            <div>
                                <button className="btn btn-sm btn-success me-2" onClick={() => addUpd(user)}><i className="fas fa-solid fa-plus"></i> Agregar</button>
                                <button className="btn btn-sm btn-success" onClick={() => setModalImport(true)}><i className="fas fa-solid fa-upload"></i> Importar</button>
                            </div>
                        </DataTableHelper>
                    </div>
                </Container>
                {modal.show &&
                    <AddUpdModal 
                        modal={modal}
                        setModal={setModal}
                        items={items}
                        setItems={setItems}
                        user={user}
                        setUser={setUser}
                        categories={categories}
                    />
                }
            </div>
            <div>
	        <Modal isOpen={modalImport}>
				<ModalHeader>Importar</ModalHeader>
				<ModalBody>
				<div className="row">
					<div className="col-sm-12">
					    <label className="form-label">Formato de productos:</label>
					    <input className="form-control" name="import_file" type="file" onChange={ e => [fileValidate(e.target.files[0])] } />
					    {
					    	errorImport && <div className="form-text text-danger">{errorImport.file && errorImport.file?.[0]}</div>
					    }
					</div>
                    
                    <a className="mt-3" href={formato} target="_blank" rel="noreferrer">
                        <i className="fas fa-downlload"></i>Descargar formato de productos
                    </a>
                    
				</div>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={doImport} disabled={loadingImport}>
						{loadingImport && <Spinner size="sm"></Spinner> } Guardar
					</Button>{' '}
					<Button color="secondary" onClick={() => {setModalImport(false)}}>Cancelar</Button>
				</ModalFooter>
	        </Modal>
		</div>
        </React.Fragment>
    );
};

export default Products;