import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postUser,putUser,getCompanies } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import Validate from './Validate'

const AddUpdModal = ({modal,setModal,items, setItems, setUser,user}) => {
	const [selects, setSelects] = useState({profiles:[{id:0,name:''}],companies:[{id:0,name:''}]});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const authUser = getStorageUser();
	const handleClose = () => {
		setModal({...modal,show:false,update:false});
		setUser( {...user,
    		id: 0,
    		name: '',
    		email: '',
    		profile_id: 0,
    		profile: {
    			id: 0,
    			name: ''
    		},
    	});	
    }

    const handlerOnChange = (value,name) => {
    	if(name=='profile' || name=='company'){
    		setUser({...user, [name=='profile' ? 'profile_id':'company_id']:value.id,[name]:value});
    	}else{
    		setUser({...user, [name]:value});
    	}
    }

    const save = () => {
    	ToastHelper('mensaje','error')
    	setErrors([])
    	const validate = Validate(user);
    	if(validate != undefined){
            setErrors(validate);
            setLoading(false)
            return;
        }
    	setLoading(true)
    	if(modal.update){
    		putUser(user).then(response => {
    			responseAction(response);			
    		})
    	}else{
    		postUser(user).then(response => {
    			responseAction(response);
    		})	
    	}

    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.user;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

    useEffect(() => {
    	getProfilescat().then(response => {
    		console.log()
    		if(authUser.profile_id==1){
    			getCompanies().then(resp => {
					//setSelects({...selects,});
					setSelects({...selects,companies:resp.data,profiles:response.profiles});
				});	
    		}else{
    			setSelects({...selects,profiles:response.profiles});
    		}
    		
    	});
    	
    }, [])

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>Agregar / Editar</ModalHeader>
				<ModalBody>
				<div className="row">
					<div className="col-sm-12">
					    <label className="form-label">Nombre:</label>
					    <input className="form-control" name="name" type="text" onChange={ e => handlerOnChange(e.target.value,'name') } value={user.name}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.name && errors.name[0]}</div>
					    }
					</div>
					<div className="col-sm-6">
					    <label className="form-label">Email:</label>
					    <input className="form-control"  name="email" type="text" onChange={ e => handlerOnChange(e.target.value, 'email') } value={user.email}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.email && errors.email[0]}</div>
					    }
					</div>
					{authUser.profile_id==1 &&
					<div className="col-sm-6">
					    <label className="form-label">Empresa:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							isClearable={true}
							defaultValue={selects.companies.find(company => company.id === user.company_id)}
							isSearchable={true}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option.id}`}
							name="color"
							options={selects.companies}
							onChange={ e => handlerOnChange(e, 'company') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.company_id && errors.company_id[0]}</div>
					    }
					</div>
					}
					<div className="col-sm-6">
					    <label className="form-label">Perfil:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							isClearable={true}
							defaultValue={selects.profiles.find(profile => profile.id === user.profile_id)}
							isSearchable={true}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option.id}`}
							name="color"
							options={selects.profiles}
							onChange={ e => handlerOnChange(e, 'profile') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.profile_id && errors.profile_id[0]}</div>
					    }
					</div>

					</div>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save} disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;