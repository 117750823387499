const sizeRules = (sizeFile, sizeRule, name) => {
    let message = "";
    if(sizeRule !== null){
        message = sizeFile >= (sizeRule * 1000000) ? 'El tamaño del archivo debe ser menor o igual a '+sizeRule+' mb. ' : '';
    }

    return message;
}

const typeRules = (typeFile, typeRule, name) => {
    let message = "";
    console.log('typeFile', typeRule, typeFile, typeFile != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
    switch (typeRule){
        case ('pdf'):
        message += typeFile != 'application/pdf' ? 'El '+name+' debe ser de tipo pdf. ' : '';
        break;
        case ('xls'):
        message += (typeFile == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || typeFile == 'application/xls' || typeFile == 'application/xlsx') ? '' : 'El '+name+' debe ser de tipo xls.';
        break;
        case ('image'):
        message += (typeFile == 'image/png' || typeFile == 'image/jpg' || typeFile == 'image/jpeg') ? '' : 'El '+name+' debe ser de tipo png o jpg ' ;
        break;
        default:
        message += '';
        break;
    }
    return message;
}

const isRequired = (file, requiredRule, name) => {
    let message = '';
    if(requiredRule){
        message = file == '' ? 'El '+name+' es requerido.' : '';
    }
    return message;
}

const ValidateFileHelper = (file, rules, nameInput = null) => {
    let name = (nameInput != null) ? nameInput : 'file';
    let required = isRequired(file != '' && file != undefined ? file : '', rules.required, name);
    let type = typeRules(file != '' && file != undefined ? file.type : '', rules.type, name);
    let size = sizeRules(file != '' && file != undefined ? file.size : '', rules.size, name);
    let error = "";

    if(required !== ''){
        error += required;
    }

    if(type !== ''){
        error += type;
    }

    if(size !== ''){
        error += size;
    }

    if(error !== ''){
        return {file:[error]};
    }else{
        return undefined;
    }
}

export default ValidateFileHelper;
