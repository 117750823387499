import ValidateHelper from "../../helpers/ValidateHelper";

const Validate = (inputs, arrays = false) => {
	const name = ValidateHelper({
		input: inputs.name,
		attribute: 'nombre',
		required: true, 
		min:4, 
		max:30,
		type: 'text'
	});

	let errors = {
		name: [],
	};

	let flag = false;

	if (name != undefined){
		errors.name = [name];
		flag = true;
	}
	
	return flag ? errors : undefined;
}

export default Validate;