import axios from "axios"
import { error } from "jquery"
import { del, get, post, postFile, put, postData} from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}
//reset pasword
export const userResetPasw = user => post(url.RESET_PASSW, user)
export const changePassw = data => post(url.CHANGE_PASSW, data)
export const sendConfirmation = data => post(url.SEND_CONFIRMATION, data)

// Login Method

//export const postLoginToken = data => post(url.POST_LOGIN_TOKEN, data)

export const postLoginToken = (data) => {
  const headers = {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  };
  
  return axios
    .post(process.env.REACT_APP_BACKEND_API_URL + url.POST_LOGIN_TOKEN, data, { headers: headers })
    .then(response => {
      if (response.status >= 200 || response.status <= 299) return response.data
      throw response.data
    })
    .catch(err => {
      console.log('err', err);
      var message = ''
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found"
            break
          case 422:
            message = getError(err)
            break
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team"
            break
          case 401:
            message = "Invalid credentials"
            break
          default:
            message = err.response?.data?.message || err.message
            break
        }
      } else {
        message = err.message
      }
      throw message
    })
}

// get Users
export const GET_ME = "/user";
export const getUsers = (config) => get(url.RESOURCE_USERS,config);
export const postUser = user => post(url.RESOURCE_USERS, user)
export const putUser = user => put(`${url.RESOURCE_USERS}/${user.id}`, user)
export const deleteUser = id => del(`${url.RESOURCE_USERS}/${id}`)
export const getProfiles = (id) => get(`${url.RESOURCE_USERS}/${id}`);
export const postFileProfile = (id,data) => postFile(`${url.FILE_PROFILE_USER}/${id}`, data)
//catalogs
export const getProfilescat = ()  => get(url.GET_PROFILES);
export const getCategoriesParents = ()  => get(url.RESOURCE_CATEGORIES+'/parents');

//condiguration
export const getConfigurations = (config) => get(url.RESOURCE_CONFIGURATIONS,config);
export const postFileFileConfiguration = configuration => postData(url.RESOURCE_CONFIGURATIONS, configuration)
export const getConfigurationsSelects = () => get(url.SELECTS_CONFIGURATIONS);
export const getConfiguration = () => get(url.GET_ACTIVE_CONFIGURATION);
export const deleteConfiguration = id => del(`${url.RESOURCE_CONFIGURATIONS}/${id}`)
//company
export const getCompanies = config => get(url.RESOURCE_COMPANIES,config);
export const postCompany = company => post(url.RESOURCE_COMPANIES,company);
export const putCompany = company => put(`${url.RESOURCE_COMPANIES}/${company.id}`,company);

// products
export const getProducts = (config) => get(url.RESOURCE_PRODUCTS,config);
export const postProduct = product => post(url.RESOURCE_PRODUCTS, product)
export const putProduct = product => put(`${url.RESOURCE_PRODUCTS}/${product.id}`, product)
export const deleteProduct = id => del(`${url.RESOURCE_PRODUCTS}/${id}`)
export const postFileProduct = (data) => postFile(`${url.RESOURCE_PRODUCTS}/import`, data)

// products
export const getCategories = (config) => get(url.RESOURCE_CATEGORIES,config);
export const postCategory = category => post(url.RESOURCE_CATEGORIES, category)
export const putCategory = category => put(`${url.RESOURCE_CATEGORIES}/${category.id}`, category)
export const deleteCategory = id => del(`${url.RESOURCE_CATEGORIES}/${id}`)

//profiles - permissions
export const getProfilesPermissions = (config) => get(url.RESOURCE_PROFILES,config);
export const postProfile = profile => post(url.RESOURCE_PROFILES,profile);
export const putProfile = profile => put(`${url.RESOURCE_PROFILES}/${profile.id}`,profile);
export const getPermissions = (id,config) => get(`${url.RESOURCE_PERMISSIONS}/${id}`,config);
export const getSelectsPermissions = (id) => get(`${url.PERMISSIONS_SELECT}/${id}`);
export const postPermission = permission => post(url.RESOURCE_PERMISSIONS,permission);
export const putPermission = permission => put(`${url.RESOURCE_PERMISSIONS}/${permission.id}`,permission);
export const deletePermission = id => del(`${url.RESOURCE_PERMISSIONS}/${id}`)
export {
  getLoggedInUser,
  isUserAuthenticated,
  //postFakeRegister,
  //postFakeProfile,
  //postFakeForgetPwd,
  //postJwtRegister,
  //postJwtLogin,
  //postJwtForgetPwd,
  //postJwtProfile
}
