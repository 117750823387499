import ValidateHelper from "../../helpers/ValidateHelper";

const Validate = (inputs, arrays = false) => {
	const name = ValidateHelper({
		input: inputs.name,
		attribute: 'nombre',
		required: true, 
		min:1, 
		max:250,
		type: 'text'
	});

	const parent_id = ValidateHelper({
		input: inputs.parent_id,
		attribute: 'parent_id',
		required: false,
		type: 'integer'
	});

	let errors = {
		name: [],
		email: [],
		profile_id: []
	};

	let flag = false;

	if (name != undefined){
		errors.name = [name];
		flag = true;
	}
	if (parent_id != undefined){
		errors.parent_id = [parent_id];
		flag = true;
	}

	return flag ? errors : undefined;
}

export default Validate;