import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  leftSideBarThemeTypes,
} from "../../constants/layout";
import {
  changeSidebarTheme,
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode
} from "../../store/actions";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getStorageConfig,getStorageCompany } from '../../helpers/api_helper';

import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";


const Dashboard = () => {
    const configuration  = getStorageConfig();
    const dispatch = useDispatch();
    useEffect(() => {
       // dispatch(changeSidebarTheme(configuration.menu.option_name))
    }, [])
    //meta title
    document.title="Dashboard | Minia - React Admin & Dashboard Template";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />

                    
                </Container>
            </div>
        </React.Fragment>
    );
}
export default Dashboard;