import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";
import DataTableHelper from "../../helpers/tables/DataTableHelper"
import Columns from './Columns'
import AddUpdModal from './AddUpdModal'
import { getUsers,deleteUser } from '../../helpers/backend_helper';
import {DestroyAlertHelper,Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import { getPolicyUser } from '../../helpers/api_helper';
import {Container} from "reactstrap";

const Perfiles = () => {
    const policy = getPolicyUser();
    
    const [pending, setPending] = useState(false);
    const [modal, setModal] = useState({show:false,update:false});
    const [dataPag, setDataPag] = useState({});
    const [filters,setFilters] = useState({page:1,perPage:20,search:'',orderBy:'id',order:'asc'});
    const [items, setItems] = useState([]);
    const [user, setUser] = useState({
        id: 0,
        name: '',
        email: '',
        profile_id: 0,
        profile: {
            id: 0,
            name: ''
        },
        company: {
            id: 0,
            name: ''
        },
    });
	const dispatch = useDispatch()
    useEffect(() => {
        //ToastHelper('mensaje','error')
        let params={params:filters}
        setPending(true)
        /*
        getUsers(params).then(response => {
            setItems(response.data)
            setDataPag(response.meta)
            //console.log(response)
        }) */
        setItems([
            {
                name: 'Prudente',
                description: 'Es un portafolio especializado en acciones, diseñado para buscar generar la mayor ganancia con un riesgo balanceado. El portafolio de inversión que tiene como objetivo superar los rendimientos del IPC para tal objetivo la administración  selecciona los activos objeto de inversión de acuerdo a un minucioso estudio fundamental de las empresas para conseguir la mayor rentabilidad por unidad de riesgo asumido, además de seleccionar empresas con gran potencial de rentabilidad, por fortalezas sectoriales, macroeconómicas o por cifras de rentabilidad superior.',
                profile: '2',
                company_id: null,
                id: '1',
                can: {update: true, delete: true, view: true}
            },
            {
                name: 'Tenaz',
                description: 'Es un portafolio especializado en acciones, diseñado para buscar generar la mayor ganancia con un riesgo balanceado. El portafolio de inversión que tiene como objetivo superar los rendimientos del IPC para tal objetivo la administración  selecciona los activos objeto de inversión de acuerdo a un minucioso estudio fundamental de las empresas para conseguir la mayor rentabilidad por unidad de riesgo asumido, además de seleccionar empresas con gran potencial de rentabilidad, por fortalezas sectoriales, macroeconómicas o por cifras de rentabilidad superior.',
                profile: '2',
                company_id: null,
                id: '1',
                can: {update: true, delete: true, view: true}
            }
        ])
        setPending(false)
    }, [filters])
    const addUpd = (row) => {
        if(row.id){
            setUser(row)
            setModal({...modal,show:true,update:true})
        }else{
            setModal({...modal,show:true,update:false})
        }
    }
    const destroy = (id) => {
        DestroyAlertHelper(async confirmed => {
            if (!confirmed) { return; }
            deleteUser(id).then(response => {
                Alert(response.message,response.status)
                if (response.status) {
                    setItems(
                        items.filter(i => i.id != id)
                    );
                }           
            })
        });
    }

    const columns = Columns(addUpd,destroy);
	document.title = "Perfiles";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div className="col-12">
                        <DataTableHelper tittle="Perfiles" columns={columns} items={items} pending={pending} config={dataPag} filters={filters} setFilters={setFilters}>
                            <button className="btn btn-sm btn-success" onClick={() => addUpd(user)}><i className="fas fa-solid fa-plus"></i> Agregar</button>
                        </DataTableHelper>
                    </div>
                </Container>
                {modal.show &&
                    <AddUpdModal 
                        modal={modal}
                        setModal={setModal}
                        items={items}
                        setItems={setItems}
                        user={user}
                        setUser={setUser}
                    />
                }
            </div>
        </React.Fragment>
    );
};

export default Perfiles;