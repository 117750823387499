import React, {useEffect, useState, useContext} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Label, Card, Row, Col } from 'reactstrap';
import Select from 'react-select';
import { getProfilescat,postUser,putUser,getCompanies, putProduct, postProduct, getCategoriesParents } from '../../helpers/backend_helper';
import { getStorageUser } from '../../helpers/api_helper';
import {Alert,ToastHelper} from '../../helpers/alerts/alertHelper';
import Validate from './Validate'
import Dropzone from 'react-dropzone';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const AddUpdModal = ({modal,setModal,items, setItems, setUser,user, categories}) => {
	const [selects, setSelects] = useState({
		categories: categories|| [],
		types:[{id:1,name:'Estudios PCR'}, {id:2,name:'Análisis clínicos'}],
		subtypes:[{id:1,name:'Análisis individual'}, {id:2,name:'Perfil'}],
	});
	const [ errors, setErrors ] = useState([]);
	const [ loading, setLoading ] = useState(false);
	const [ isEdit, setIsEdit ] = useState(false);
	const [ selectedFiles, setSelectedFiles ] = useState([]);

	const authUser = getStorageUser();

	const handleClose = () => {
		setModal({...modal,show:false,update:false});
		setUser( {...user,
    		id: 0,
    		code: '',
			name: '',
    		desc: '',
			type: '',
			subtype: '',
			tests: '',
			eta: '',
    		category_id: null,
    		category: {
    			id: 0,
    			name: ''
    		},
    	});	
    }

    const handlerOnChange = (value,name) => {
    	if(name=='category_id' || name=='type' || name=='subtype'){
			console.log(name, 'value', value)
			setUser({...user, [name]:value.id});
    	}else{
    		setUser({...user, [name]:value});
    	}
		console.log('data',user)
    }

    const save = () => {
    	ToastHelper('mensaje','error')
    	setErrors([])
    	const validate = Validate(user);
    	if(validate != undefined){
			console.log('validate',validate)
            setErrors(validate);
            setLoading(false)
            return;
        }
    	setLoading(true)
    	if(modal.update){
    		putProduct(user).then(response => {
    			responseAction(response);			
    		})
    	}else{
    		postProduct(user).then(response => {
    			responseAction(response);
    		})	
    	}
    }

    const responseAction = (response) => {
    	if(response.status){
    		const newItem = response.product;
    		let array = [];
    		if(modal.update){
    			array = items.map(item => item.id !== newItem.id ? item : newItem);
    		}else{
    			array = [...items, newItem];
    			array.sort((a, b) => {
    				return a.id - b.id;
    			});
    		}
    		setItems(array);
			handleClose()
			setLoading(false)
    	}else{
    		setLoading(false)
    		setErrors(response.response.data.errors)
    	}
    }

	function handleAcceptedFiles(files) {
		files.map((file) =>
		  Object.assign(file, {
			preview: URL.createObjectURL(file),
			formattedSize: formatBytes(file.size),
		  })
		);
		setSelectedFiles(files);
		validation.setFieldValue('assetId','1',true)
	}

	/**
	 * Formats the size
	 */
	function formatBytes(bytes, decimals = 2) {
	if (bytes === 0) return "0 Bytes";
	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

	const i = Math.floor(Math.log(bytes) / Math.log(k));
	return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
	}

	console.log('item', user)

    return (
		<div>
	        <Modal isOpen={modal.show}>
				<ModalHeader>Agregar / Editar</ModalHeader>
				<ModalBody>
				<div className="row">

					<div className="col-sm-12">
					    <label className="form-label">Código Labiomola:</label>
					    <input className="form-control" name="code" type="text" onChange={ e => handlerOnChange(e.target.value,'code') } value={user.code}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.code && errors.code[0]}</div>
					    }
					</div>

					<div className="col-sm-6">
					    <label className="form-label">Tipo:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							isClearable={true}
							defaultValue={selects.types.find(type => type.id == user.type)}
							isSearchable={true}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option.id}`}
							name="color"
							options={selects.types}
							onChange={ e => handlerOnChange(e, 'type') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.type && errors.type[0]}</div>
					    }
					</div>

					<div className="col-sm-6">
					    <label className="form-label">Subtipo:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							isClearable={true}
							defaultValue={selects.subtypes.find(subtype => subtype.id == user.subtype)}
							isSearchable={true}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option.id}`}
							name="color"
							options={selects.subtypes}
							onChange={ e => handlerOnChange(e, 'subtype') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.subtype && errors.subtype[0]}</div>
					    }
					</div>

					<div className="col-sm-6">
					    <label className="form-label">Categoría:</label>
					    <Select
							className="basic-single"
							classNamePrefix="select"
							isClearable={true}
							defaultValue={categories.find(cat => cat.id == user.category_id)}
							isSearchable={true}
							getOptionLabel={(option) => `${option.name}`}
							getOptionValue={(option) => `${option.id}`}
							name="color"
							options={categories}
							onChange={ e => handlerOnChange(e, 'category_id') }
						/>
					    {
					    	errors && <div className="form-text text-danger">{errors.category_id && errors.category_id[0]}</div>
					    }
					</div>
					
					<div className="col-sm-12">
					    <label className="form-label">Nombre Prueba:</label>
					    <input className="form-control" name="name" type="text" onChange={ e => handlerOnChange(e.target.value,'name') } value={user.name}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.name && errors.name[0]}</div>
					    }
					</div>
					<div className="col-sm-12">
					    <label className="form-label">Pruebas:</label>
					    <textarea className="form-control" name="name" type="text" onChange={ e => handlerOnChange(e.target.value,'tests') } value={user.tests}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.tests && errors.tests[0]}</div>
					    }
					</div>
					<div className="col-sm-12">
					    <label className="form-label">Tiempo entrega (días):</label>
					    <input className="form-control"  name="number" type="number" onChange={ e => handlerOnChange(e.target.value, 'eta') } value={user.eta}/>
					    {
					    	errors && <div className="form-text text-danger">{errors.eta && errors.eta[0]}</div>
					    }
					</div>
				</div>	
				</ModalBody>
				<ModalFooter>
					<Button color="primary" onClick={save} disabled={loading}>
						{loading && <Spinner size="sm"></Spinner> } 
						Guardar
					</Button>{' '}
					<Button color="secondary" onClick={handleClose}>Cancelar</Button>
				</ModalFooter>
	        </Modal>
		</div>
	);
}

export default AddUpdModal;