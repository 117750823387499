import React from "react";
import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard/index";

//Utility
import PageMaintenance from "../pages/Utility/PageMaintenance";
import PagePricing from "../pages/Utility/PagePricing/index";
import Error404 from "../pages/Utility/Error404";
import Error500 from "../pages/Utility/Error500";
import Configuration from "../pages/Utility/Configuration/Configuration";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//AuthenticationInner related pages
import PageLogin from "../pages/AuthenticationInner/PageLogin";
import PageConfirm from "../pages/Authentication/PageRegister";
//import userProfile from "../pages/Authentication/user-profile";
import Users from "../pages/Users/Users";
import Profile from "../pages/Users/Profile";

//company
import Company from "../pages/Companies/Company";

import Profiles from "../pages/Profiles/Profiles";
import Permissions from "../pages/Profiles/Permissions/Permissions";
import Products from "../pages/Products/Products";
import Clients from "../pages/Clients/Clients";
import Perfiles from "../pages/Perfiles/Perfiles";
import Finder from "../pages/Finder/Finder";
import Categories from "../pages/Categories/Categories";

const userRoutes = [ 
  //dashboard
  { path: "/dashboard", component: Dashboard },

  { path: "/products", component: Products },
  { path: "/categories", component: Categories },

  { path: "/clients", component: Clients },

  { path: "/perfiles", component: Perfiles },

  //List_Users Routes
  { path: "/users", component: Users },
  { path: "/users/profile/:id", component: Profile },

  //configurations
  { path: "/configuration/:id?", component: Configuration },

  //Company
  { path: "/companies", component: Company },

  //ProfilesPremissons
  { path: "/profiles", component: Profiles },
  { path: "/profile/:id", component: Permissions },
  // this route should be at the end of all other routes
  //{ path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },

  //AuthenticationInner pages
  { path: "/page-login", component: PageLogin },
  { path: "/verifyMail/:id/:token/:reset?", component: PageConfirm },

  //Utility page
  { path: "/pages-maintenance", component: PageMaintenance },
  { path: "/pages-404", component: Error404 },
  { path: "/pages-500", component: Error500 },
];

const publicRoutes = [
  //authencation page
  { path: "/", component: () => <Redirect to="/login" /> },
];

export { userRoutes, authRoutes, publicRoutes };
