import React from 'react';
import { Link, withRouter } from "react-router-dom"

const Columns = ( addUpd, destroy ) => {
		let columns = [
			{
				id: 'name',
				name: 'Nombre',
				selector: row => row.name,
				sortable: true,
				wrap: true,
				width:'20%'
			},
			{
				id: 'actions',
				name: 'Actions',
				width:'20%',
				button: true,
				cell: row => (
					<div className="d-flex justify-content-between">
						{row.can.update && 
						<div className="p-2">
                            <button className="btn btn-warning btn-sm" title='Editar usuario' onClick={() => addUpd(row)}><i className="fas fa-edit"></i></button>
                        </div>
                        }

                        <div className="p-2">
                            <button className="btn btn-danger btn-sm" title='Eliminar usuario' onClick={() => destroy(row.id)}><i className="fas fa-trash"></i></button>
                        </div>
                    
                    	{row.can.view &&
                        <div className="p-2">
	                        <Link to={`/users/profile/${row.id}`} className="btn btn-info btn-sm" title='Perfil de usuario'>
	                            <i className="fa fa-address-book"></i>
							</Link>
                        </div>
                        }
					</div>
				),
			}
		];
	return columns;
}

export default Columns;