import ValidateHelper from "../../helpers/ValidateHelper";

const Validate = (inputs, arrays = false) => {
	const name = ValidateHelper({
		input: inputs.name,
		attribute: 'nombre',
		required: true, 
		min:4, 
		max:30,
		type: 'text'
	});

	const email = ValidateHelper({
		input: inputs.email,
		attribute: 'email',
		required: true, 
		min:4, 
		type:'email'
	});

	const profile_id = ValidateHelper({
		input: inputs.profile_id,
		attribute: 'perfil',
		required: true, 
		type: 'integer'
	});

	let errors = {
		name: [],
		email: [],
		profile_id: []
	};

	let flag = false;

	if (name != undefined){
		errors.name = [name];
		flag = true;
	}
	if (email != undefined){
		errors.email = [email];
		flag = true;
	}
	if (profile_id != undefined){
		errors.profile_id = [profile_id];
		flag = true;
	}

	

	return flag ? errors : undefined;
}

export default Validate;