import ValidateHelper from "../../helpers/ValidateHelper";

const Validate = (inputs, arrays = false) => {
	const name = ValidateHelper({
		input: inputs.name,
		attribute: 'nombre',
		required: true, 
		min:1, 
		max:100,
		type: 'text'
	});

	const code = ValidateHelper({
		input: inputs.code,
		attribute: 'code',
		required: true, 
		min:1, 
		type:'text'
	});

	const type = ValidateHelper({
		input: inputs.type,
		attribute: 'type',
		required: true,
		min:1, 
		type:'text'
	});

	const subtype = ValidateHelper({
		input: inputs.subtype,
		attribute: 'subtype',
		required: true, 
		min:1, 
		type:'text'
	});

	const category_id = ValidateHelper({
		input: inputs.category_id,
		attribute: 'category_id',
		required: false, 
		type: 'integer'
	});

	let errors = {
		name: [],
		code: [],
		category_id: []
	};

	let flag = false;

	if (type != undefined){
		errors.type = [type];
		flag = true;
	}
	if (subtype != undefined){
		errors.subtype = [subtype];
		flag = true;
	}
	if (name != undefined){
		errors.name = [name];
		flag = true;
	}
	if (code != undefined){
		errors.code = [code];
		flag = true;
	}
	if (category_id != undefined){
		errors.category_id = [category_id];
		flag = true;
	}

	return flag ? errors : undefined;
}

export default Validate;