import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUser } from "../../store/actions"
import { getMe } from "../../helpers/backend_helper"
// import images
import logo from "../../assets/images/logo-sm.svg"
//Import config
import CarouselPage from "../AuthenticationInner/CarouselPage"
//Configuracion
import { getConfiguration } from '../../helpers/backend_helper';
//import { getStorageConfig } from '../../helpers/api_helper';

const Finder = props => {

  const dispatch = useDispatch()
  const [configuration, setConfiguration] = useState({image:'',name:'',url:''})
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  useEffect(() => {

  }, [])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "admin@labiomola.com" || '',
      password: "123456" || '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    }
  });

  document.title= "Login";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container className="p-0">
          <Row className="mt-5">
            <Col md={3}>
              <img className=""  src="/images/labiomola_logo.png" alt="" height="80" />
            </Col>
            <Col className="position-relative">
                <div className="p3">
                  <Input
                    name="email"
                    className="form-control"
                    placeholder="Buscar estudio o prueba"
                    type="email"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={""}
                    invalid={
                      validation.touched.email && validation.errors.email ? true : false
                    }
                  />
                </div>
            </Col>
            <Col className="col-auto">
              <button
                className="btn btn-primary btn-block"
                type="submit"
              >
                Buscar
              </button>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={configuration.url} alt="" height="28" /> <span className="logo-txt">{configuration.name}</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        resultados
                      </div>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} {configuration.name} </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Finder)

Finder.propTypes = {
  history: PropTypes.object,
}