import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Row, Col, Alert, Container, Form, Input, FormFeedback, Label } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux"
import { withRouter, Link } from "react-router-dom"
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// actions
import { loginUser } from "../../store/actions"
import { getMe } from "../../helpers/backend_helper"
// import images
import logo from "../../assets/images/logo-sm.svg"
//Import config
import CarouselPage from "../AuthenticationInner/CarouselPage"
//Configuracion
import { getConfiguration } from '../../helpers/backend_helper';
//import { getStorageConfig } from '../../helpers/api_helper';

const Login = props => {

  const dispatch = useDispatch()
  const [configuration, setConfiguration] = useState({image:'',name:'',url:''})
  const { error } = useSelector(state => ({
    error: state.Login.error,
  }))
  useEffect(() => {

  }, [])
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    }
  });

  document.title= "Login";
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col md={4}></Col>
            <Col md={4} className="">
              <div className="auth-full-page-content d-flex p-sm-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5 text-center">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img src={configuration.url} alt="" height="28" /> <span className="logo-txt">{configuration.name}</span>
                      </Link>
                    </div>
                    <div className="auth-content my-auto">
                      <div className="text-center">
                        <img className="mb-5"  src="/images/labiomola_logo.png" alt="" height="120" />
                        <h5 className="mb-0">¡Bienvenido!</h5>
                        <p className="text-muted mt-2">Introduce tus credenciales para ingresar.</p>
                      </div>
                     <Form
                        className="custom-form mt-4 pt-2"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                      >
                        {error ? <Alert color="danger">{error}</Alert> : null}
                        <div className="mb-3">
                          <Label className="form-label">Email</Label>
                          <Input
                            name="email"
                            className="form-control"
                            placeholder="Enter email"
                            type="email"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ""}
                            invalid={
                              validation.touched.email && validation.errors.email ? true : false
                            }
                          />
                          {validation.touched.email && validation.errors.email ? (
                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="mb-3">
                          <Label className="form-label">Constraseña</Label>
                          <Input
                            name="password"
                            value={validation.values.password || ""}
                            type="password"
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password && validation.errors.password ? true : false
                            }
                          />
                          {validation.touched.password && validation.errors.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </div>

                        <div className="row mb-4">
                          <div className="col">
                            <div className="form-check">
                              <Link to="/forgot-password" className="">
                                ¿Olvidaste tu contraseña?
                              </Link>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn btn-primary btn-block"
                                type="submit"
                              >
                                Ingresar
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </div>
                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">© {new Date().getFullYear()} {configuration.name} </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}