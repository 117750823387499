import axios from "axios"
import accessToken from "./jwt-token-access/accessToken"

//pass new generated access token here
const token = accessToken

//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
})

axiosApi.defaults.headers.common["Authorization"] = token

axiosApi.interceptors.response.use(
  response => response,
  error => Promise.reject(error)
)
export  function getStorageUser() {
  const obj = JSON.parse(localStorage.getItem("authUser"))
  return obj && 'user' in obj ? obj.user:{};
}

export function getPolicyUser(){
  return {
    profile_id: 2,
    users: {view: true, create: true}, 
    configuration: {view: true, create: true}, 
    profiles: {view: true, create: true}, 
  }
  const obj = JSON.parse(localStorage.getItem("authUser"))
  return obj && 'user' in obj ? obj.user.can:{configurations:false,users:false};
}

export async function get(url, config = {}) {
  return await axiosApi.get(url, { ...config }).then(response => response.data)
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data)
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data)
}
