import PropTypes from 'prop-types';
import React, { useState,useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
//Import Icons
import FeatherIcon from "feather-icons-react";
// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
// Import menuDropdown
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
// import images
import logoSvg from "../../assets/images/logo-sm.svg";
//redux
import { useSelector, useDispatch } from "react-redux";
// Redux Store

import Select from 'react-select';
import {
  leftSideBarThemeTypes,
} from "../../constants/layout";
import {
  changeSidebarTheme,
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode
} from "../../store/actions";
import { getStorageConfig,getStorageCompany } from '../../helpers/api_helper';

const Header = props => {
  const dispatch = useDispatch();
  const { showRightSidebar } = useSelector((state) => ({
    showRightSidebar: state.Layout.ShowRightSidebar
  }));
  const [socialDrp, setsocialDrp] = useState(false);
  
  const [isClick, setClick] = useState(true);
  const configuration  = getStorageConfig();
  const company = getStorageCompany();
  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute('data-sidebar-size', 'sm');
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute('data-sidebar-size', 'lg');
    }
  }

 

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
          <br/> 
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-dark">

                <span className="logo-sm">
                  <img src="/images/labiomola_logo.png" alt="" height="55" />
                </span>
                <span className="logo-lg">
                  <img src="/images/labiomola_logo.png" alt="" height="55" />
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={configuration.logo.image ? configuration.logo.image:''} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={configuration.logo.image ? configuration.logo.image:''} alt="" height="24" /> <span className="logo-txt">{company.name}</span>
                </span>
              </Link>
            </div>
            <button
              onClick={() => {
                tToggle();
              }}
              type="button" className="btn btn-sm px-3 font-size-16 header-item" id="vertical-menu-btn">
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>

          <div className="d-flex">
            <ProfileMenu/>
          </div>
        </div>
      </header>

    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changelayoutMode: PropTypes.func,
  layoutMode: PropTypes.any,
};

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    layoutMode
  } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, layoutMode };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})((Header));
